import React, {memo} from 'react';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import PageWrapper from '../containers/PageWrapper';
import ContactForm from '../modules/ContactForm';

import Section from '../components/Section';
import UnderlineHeading from '../components/UnderlineHeading';
import SectionDescription from '../components/SectionDescription';

import Button from '../components/Button';

const Products = ({data}) => {
  const {t} = useTranslation();

  return (<PageWrapper
      title="Products"
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      urlPath="products"
      keywords={['coderylabs', 'products', 'newsletter', 'extended webcontent selector']}
    >
    <Section>
      <UnderlineHeading><Trans>Company products</Trans></UnderlineHeading>
      <SectionDescription>
        <Trans>Company products desc</Trans>
      </SectionDescription>
    </Section>

    <Section theme="dark" id="newsletter-campaigns">
      <UnderlineHeading>Newsletter</UnderlineHeading>
      <Content>
        <ProductDescription>
          <div dangerouslySetInnerHTML={{__html: t('Newsletter module desc')}}/>
        </ProductDescription>
        <ImageContainer>
          <Img fluid={data.newsletter.childImageSharp.fluid} alt="NEWSLETTER - MANUAL AND AUTOMATIC EMAIL CAMPAIGNS"/>
        </ImageContainer>
      </Content>
      <Button href="https://web.liferay.com/marketplace/-/mp/application/176012243" title="Free download">
        <Trans>Free download</Trans>
      </Button>
    </Section>

    <Section id="limiter-selector">
      <UnderlineHeading>Storage limiter</UnderlineHeading>
      <Content>
        <ProductDescription>
          <div dangerouslySetInnerHTML={{__html: t('Limiter module desc')}}/>
        </ProductDescription>
        <ImageContainer>
          <Img fluid={data.limiter.childImageSharp.fluid} alt="Storage Limiter"/>
        </ImageContainer>
      </Content>
      <Button href="https://web.liferay.com/marketplace/-/mp/application/176011767" title="Free download">
        <Trans>Free download</Trans>
      </Button>
    </Section>

    <Section id="starred-selector">
      <UnderlineHeading>FavDocs</UnderlineHeading>
      <Content>
        <ProductDescription>
          <div dangerouslySetInnerHTML={{__html: t('Starred module desc')}}/>
        </ProductDescription>
        <ImageContainer>
          <Img fluid={data.starred.childImageSharp.fluid} alt="FavDocs"/>
        </ImageContainer>
      </Content>
      <Button href="https://web.liferay.com/marketplace/-/mp/application/176012091" title="Free download">
        <Trans>Free download</Trans>
      </Button>
    </Section>

    <Section id="extended-webcontent-selector">
      <UnderlineHeading>Extended Webcontent Selector</UnderlineHeading>
      <Content>
        <ProductDescription>
          <div dangerouslySetInnerHTML={{__html: t('EWS module desc')}}/>
        </ProductDescription>
        <ImageContainer>
          <Img fluid={data.ews.childImageSharp.fluid} alt="Extended Webcontent Selector"/>
        </ImageContainer>
      </Content>
      <Button href="https://web.liferay.com/marketplace/-/mp/application/173282112" title="Free download">
        <Trans>Free download</Trans>
      </Button>
    </Section>

    <Section theme="dark">
      <UnderlineHeading><Trans>Contact Us</Trans></UnderlineHeading>
      <SectionDescription>
        <Trans>Contact Us Hint</Trans>
      </SectionDescription>
      <ContactForm/>
    </Section>
  </PageWrapper>
  );
}


const ImageContainer = styled.div`
  right: 0;
  top: -30px;
  width: 100%;
  margin-bottom: 2rem;

  @media screen and (min-width: 1040px) {
    position: absolute;
    top: 0;
    width: 50%;
  }

  @media screen and (min-width: 1206px) {
    top: 150px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 2rem;
  position: relative;

  @media screen and (min-width: 1040px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1200px) {
    position: inherit;
  }
`;

const ProductDescription = styled.div`
  text-align: left;
  color: #222222;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 300;
  width: 100%;

  @media screen and (min-width: 1040px) {
    width: 50%;
  }
`;

export default memo(Products);

export const query = graphql`
  query {
    ews: file(relativePath: { eq: "products/ews.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    newsletter: file(relativePath: { eq: "products/newsletter.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    limiter: file(relativePath: { eq: "products/limiter.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    starred: file(relativePath: { eq: "products/starred.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
